import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { SeoService } from '../services/seo.service';
import { Contact, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent implements OnInit {
  constructor(private loadingService: LoadingService,
    private seoService: SeoService,
    private _digitalklangService: DigitalklangService, 
  ) {}

  async ngOnInit() {
    this.loadingService.setLoading(true);
    setTimeout(() => this.loadingService.setLoading(false), 100);

    try {
      this.loadingService.setLoading(true);
      await this._digitalklangService.getContactPage().then(contactPage => {
        if (contactPage.contact_meta_title  && contactPage.contact_meta_description) {
          this.seoService.setMetaTags(
            contactPage.contact_meta_title,
            contactPage.contact_meta_description
          );
        }
      });
      this.loadingService.setLoading(false);
    } catch (error) {
      console.error('Error loading service or quote', error);
      this.loadingService.setLoading(false);
      return null;
    }
  }
}
